import React from "react"
import { graphql } from "gatsby"
import { Row, Col, Container, ListGroup } from "react-bootstrap"
import Layout from '../components/layout'
import Jumbotron from '../components/jumbotron'
import Author from '../components/author'

export default function Template({
									 data, // this prop will be injected by the GraphQL query below.
								 }) {
	const { markdownRemark } = data // data.markdownRemark holds our post data
	const { frontmatter, html } = markdownRemark
	return (
		<Layout pageInfo={{ pageName: "news" }}>
			<div className={'article'}>
				<Jumbotron pageInfo={{title: frontmatter.title, subtitle: frontmatter.subtitle, backgroundImage: frontmatter.backgroundImage}} />

				<Container>
					<Author pageInfo={{name: frontmatter.authorName, title: frontmatter.authorTitle, date: frontmatter.date, image: frontmatter.authorImg, link: frontmatter.link, download: frontmatter.download, publisher: frontmatter.publisher, issue: frontmatter.issue}} />

					<Row>
						<Col>
							<div
								className="blog-post-content"
								dangerouslySetInnerHTML={{ __html: html }}
							/>
						</Col>
					</Row>
				</Container>
			</div>


		</Layout>
	)
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
      	authorName
      	authorTitle
      	authorImg
        date(formatString: "MMMM DD, YYYY")
        path
        title
        subtitle
        backgroundImage
        excerpt
        download
        link
        publisher
        issue
      }
    }
  }
`