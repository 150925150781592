import React from "react"

const Author = ({ children, pageInfo }) => {

	let publisher = ''
	if (pageInfo.publisher) {
		if (pageInfo.issue) {
			publisher = (
				<h5 className="mt-0">
					{pageInfo.publisher} - {pageInfo.issue}
				</h5>
			)
		} else {
			publisher = (
				<h5 className="mt-0">
					{pageInfo.publisher}
				</h5>
			)
		}
	}

	let image = ''
	if (pageInfo.image) {
		image = <img className="mr-3" src={pageInfo.image} alt={pageInfo.name} />
	}

	let download = ''
	if (pageInfo.download) {
		download = (
			<div>
				<a href={pageInfo.download} target={'_blank'}>Download Version</a>
			</div>
		)
	}

	let original = ''
	if (pageInfo.link) {
		original = (
			<div>
				<a href={pageInfo.link} target={'_blank'}>Original Article</a>
			</div>
		)
	}


	return (
		<section className={'author media'}>
			{image}
			<div className="media-body">
				{publisher}
				<div className={'article-date'}>{pageInfo.date}</div>
				<h5 className="mt-0">{pageInfo.name}</h5>
				<h6 className="mt-0">{pageInfo.title}</h6>
				{download}
				{original}
				{children}
			</div>
		</section>
	)
}

export default Author
